import create from "zustand";

interface ILeaderboards {
    leaderboards: any
    wallets: any[]
    referees: any[]
    oracleAssets: any
    monitoredWallets: any[]
    setLeaderboards: (leaderboards: any) => void
    setWallets: (wallets: any[]) => void
    setReferees: (referees: any[]) => void
    setOracleAssets: (oracleAssets: any) => void
    setMonitoredWallets: (monitoredWallets: any[]) => void
}


export const useRefreshStore = create<ILeaderboards>((set) => ({
    leaderboards: undefined,
    wallets: [],
    referees: [],
    oracleAssets: undefined,
    monitoredWallets: [],
    setLeaderboards: (leaderboards: any) => set({ leaderboards }),
    setWallets: (wallets: any) => set({ wallets }),
    setReferees: (referees: any) => set({ referees }),
    setOracleAssets: (oracleAssets: any) => set({ oracleAssets }),
    setMonitoredWallets: (monitoredWallets: any) => set({ monitoredWallets })
}));
